import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import styles from '../components/customer-stories-page/CustomerStoriesPage.module.less';
import {useIntl} from 'gatsby-plugin-intl';
import {Col, Row} from 'antd';
import VisibilitySensor from '../components/VisibilitySensor';
import Post from '../components/post/Post';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import img from '../assets/iam-mia-branding-web-app-b2b-b2c-customers.svg';

export default function Template({data, pageContext}) {
    const {category} = pageContext;
    const {formatMessage} = useIntl();

    const posts = data.allContentfulStory.edges
        .filter(({node: post}) => category !== null ? post.category.slug === category.slug : true)
        .map(({node: job}) => job);

    return (
        <Layout data={data}>
            <Helmet>
                <title>{formatMessage({id: 'products.page-title'})}</title>
                <meta name="description" content={formatMessage({id: 'products.page-description'})} />
            </Helmet>
            <section className={styles.section}>
                <div className="container">
                    <Row gutter={{xs:0, sm: 30, md: 60}}>
                        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 5}}>
                            <img src={img} className={styles.image} alt="Journey"/>
                        </Col>
                        <Col xs={{span: 12}} sm={{span: 12}} md={{span: 7}}>
                            <Title level={2} className={styles.title}>
                                {formatMessage({id: 'products.title'})}
                            </Title>
                            <Text className={styles.text}>
                                {formatMessage({id: 'products.text'})}
                            </Text>
                        </Col>
                    </Row>
                </div>
            </section>
            <VisibilitySensor>
                <div className={`${styles.section} ${styles.sectionWhite}`}>
                    <div className="container">
                        <Title level={3} className={styles.headline}>{formatMessage({id: 'products.headline'})}</Title>
                        <Row gutter={[{ xs: 0, md: 30, lg: 40, xl: 60 }, 40]}>
                            {posts.map(post => (
                                <Col xs={12} md={6} lg={6} xl={4}>
                                    <Post key={post.id} post={post} path="product" />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </VisibilitySensor>
        </Layout>
    );
};

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]},
      filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          companyName
          description { raw }
          heroImage {  
            fixed(width: 600, toFormat: PNG) {
              src
            }
            file { url }
          }
          logo { file { url } }
          category {
            slug
            color
          }
        }
      }
    }
    allContentfulArticleCategory(
      sort: {fields: createdAt, order: ASC}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          id
          title
          slug
          color
        }
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
